import BasePage from '../Base'
import Component from 'vue-class-component'

import BrandsService from '@/services/Brands'

@Component({})
export default class Brands extends BasePage {
  constructor(){
    super()
  }

      searchInput_brandsName: string = ''
      loadingSearchBrandsName: boolean = false

      brandsTable: any = {
        items: [],
        loading: false,
        headers:[
          {
            text: 'Brand Name',
            value: 'brand_name',
            sortable: false
          },
          {
            text: 'Total Products',
            value: 'total_products',
            sortable: false
          },
          {
            text: 'Total Suppliers',
            value: 'total_suppliers',
            sortable: false
          },
          {
            text: 'Website',
            value: 'website',
            sortable: false
          },
          {
            text: 'Action',
            align: 'center',
            sortable: false,
            class: 'action-table-width'
          }
        ],
        pagination: {
          rowsPerPage: -1
        }
      }

      modalAddBrand_show: boolean = false
      modalAddBrand_Fields: any = {}

      modalAlert_deleteBrand: boolean = false
      brand_id_delete: number = null
      brand_name_delete: string = ''


      mounted() {
        this.getBrandsList()
      }

      onSearch(){
        this.getBrandsList()
      }
      onReset(){
        this.searchInput_brandsName = ''
        this.getBrandsList()
      }

      AddNew_Brand(){
        try {
          this.$validator.validateAll().then(async response => {
            if (response) {
              const response = await BrandsService.createBrand(this.modalAddBrand_Fields)
              if (response.success){
                this.modalAddBrand_show = false
                this.showSnackbar({
                  text: 'Brand successfully created', 
                  color: 'green', 
                  timeout: 2000 
                })
                this.modalAddBrand_Fields = {}
                this.getBrandsList()
              }
            } 
          })
        } catch (error) {
          this.catchHandler(error)
        }
      }

      onUpdatedBrand(data: any) {
        this.modalAddBrand_show = true
        this.modalAddBrand_Fields.id = data.id
        this.modalAddBrand_Fields.name = data.name
        this.modalAddBrand_Fields.website = data.website
        this.modalAddBrand_Fields.update = true
      }
      updatedBrand_save() {
        try{
          this.$validator.validateAll().then(async response => {
            if (response) {
              const payload_data = {
                name : this.modalAddBrand_Fields.name,
                website: this.modalAddBrand_Fields.website
              }

              const response = await BrandsService.updateBrand(this.modalAddBrand_Fields.id, payload_data) 
              if (response.success){
                this.modalAddBrand_show = false
                this.showSnackbar({
                  text: 'Updated Brand Success',
                  color: 'green',
                  timeout: 2000
                })
                this.modalAddBrand_Fields = {}
                this.getBrandsList()
              }
            }
          })

        } catch(error) {
          this.catchHandler(error)
        }
      }

      onDeleteBrand(data: any){
        this.brand_name_delete = data.name
        this.modalAlert_deleteBrand = true
        this.brand_id_delete = data.id
      }
      async deletedBrand_save() {
        try {
          const response = await BrandsService.deleteBrands(this.brand_id_delete)
          if (response.success){
            this.showSnackbar({
              text: 'Deleted Success',
              color: 'teal',
              timeout: 2000
            })
            this.modalAlert_deleteBrand = false
            this.brand_id_delete = null
            this.brand_name_delete = ''
            this.getBrandsList()
          }
        } catch(error){
          this.catchHandler(error)
        }
      }

      async getBrandsList(){
        this.brandsTable.items = []
        try{
          this.brandsTable.loading = true

          const opts: any = {
            params: {
              sort: 'name'
            }
          }

          if (this.searchInput_brandsName != '') {
            opts.params = {
              ...opts.params,
              'filter[name][like]': this.searchInput_brandsName
            }
          }

          const response = await BrandsService.getBrandsLists(opts)
          for (const brand_data of response.data){
            this.brandsTable.items.push(brand_data.attributes)
          }           

        } catch(error) {
          this.catchHandler(error)
        } finally {
          this.brandsTable.loading = false
        }
      }
}